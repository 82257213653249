<template>
<div class="text-sm">
  <div class="flex justify-between items-center mb-2">
    <span class="text-base font-semibold">{{selectedMonth.format("MMMM YYYY")}}</span>
    <div class="space-x-2">
      <VButton class="max-w-[35px]" color="default" @click="selectedMonth = selectedMonth.subtract(1, 'month')">
        <ChevronLeftIcon class="w-4 h-4"/>
      </VButton>
      <VButton class="max-w-[35px]" color="default" @click="selectedMonth = selectedMonth.add(1, 'month')">
        <ChevronRightIcon class="w-4 h-4"/>
      </VButton>
    </div>
  </div>
  <div class="grid grid-cols-7 gap-0.5 select-none">
    <div class="flex items-center justify-center font-semibold py-3" v-for="wd in ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']" :key="wd">
      {{wd}}
    </div>
    <div v-for="item in dates" :key="item.date.unix()" @click="toggleDate(isActive(item.date) ? item : null)"
         class="flex items-center justify-center rounded py-3"
         :class="[
           isActive(item.date) ? 'hover:bg-gray-100 active:bg-gray-200 cursor-pointer' : '',
           item.date.month() == selectedMonth.month() ? 'text-gray-950' : 'text-gray-400',
           item.date.isSame(currentDate, 'day') && item.date.month() == selectedMonth.month() ? 'ring-1 ring-indigo-200' : '',
           item.selected && item.date.month() == selectedMonth.month() ? [
             color == 'red' ? 'bg-red-200 hover:bg-red-300 active:bg-red-400' : '',
             color == 'indigo' ? 'bg-indigo-200 hover:bg-indigo-300 active:bg-indigo-400' : ''
             ] : '',
           item.selected && item.date.month() != selectedMonth.month() ? [
             color == 'red' ? 'bg-red-50' : '',
             color == 'indigo' ? 'bg-indigo-50' : ''
             ] : '',
           ]">
      {{item.date.format("D")}}
    </div>
  </div>
</div>
</template>

<script setup lang="ts">
import type { FormKitFrameworkContext } from '@formkit/core'
import { ref, watch } from 'vue'
import dayjs, { Dayjs } from 'dayjs'
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/24/solid";
import VButton from '@/components/Ui/VButton.vue'

interface Props {
  context: FormKitFrameworkContext
}

interface CalendarDay {
  date: Dayjs
  selected: boolean
}

const props = defineProps<Props>()

const currentDate = dayjs()
const selectedMonth = ref(dayjs().date(1))
const dates = ref<CalendarDay[]>([])
const color = ref("indigo")

let modelValue: Dayjs[] = []

watch([selectedMonth, () => props.context.value], ([newSelectedMonth, newValue]) => {
  color.value = props.context.attrs.color ?? 'indigo'
  if (Array.isArray(newValue)) {
    modelValue = newValue.map(item => dayjs(item, "YYYY-MM-DD"))
  } else {
    modelValue = []
  }

  const newDays: CalendarDay[] = []

  // previous month days
  const firstDay = newSelectedMonth.day()
  for (let i = 1; i <= firstDay; i++) {
    const day = newSelectedMonth.date(-firstDay + i)
    newDays.push({
        date: day,
        selected: modelValue.some(item => item.isSame(day, 'day'))
      }
    )
  }

  // current month days
  const length = newSelectedMonth.daysInMonth()
  for (let i = 1; i <= length; i++) {
    const day = newSelectedMonth.date(i)
    newDays.push({
        date: day,
        selected: modelValue.some(item => item.isSame(day, 'day'))
      }
    )
  }

  // next month days
  for (let i = newDays.length-1; i < 41; i++) {
    const day = newDays[i].date.add(1, 'day')
    newDays.push({
        date: day,
        selected: modelValue.some(item => item.isSame(day, 'day'))
      }
    )
  }

  dates.value = newDays
}, {immediate: true})

function toggleDate(day: CalendarDay | null) {
  if (!day) {
    return
  }

  if (day.selected) {
    modelValue = modelValue.filter(item => !item.isSame(day.date, 'day'))
  } else {
    modelValue.push(day.date)
  }

  props.context.node.input(modelValue.map(item => item.format('YYYY-MM-DD')))
}

function isActive(date: Dayjs): boolean {
  const now = dayjs()
  return date.month() == selectedMonth.value.month() && (date.isAfter(now, 'day') || date.isSame(now, 'day'))
}
</script>