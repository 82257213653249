import { getCurrentUser } from 'vuefire'
import { AccountService, AccountType, OpenAPI } from '@/client'
import { useFirebaseAuth } from 'vuefire'
import type { NavigationGuardNext } from 'vue-router'

export default async ({ next }: { next: NavigationGuardNext }) => {
  const currentUser = await getCurrentUser()

  if (!currentUser) {
    return next({
      name: 'login'
    })
  }

  const auth = useFirebaseAuth()!
  // @ts-ignore
  OpenAPI.TOKEN = async () => auth.currentUser?.getIdToken(false)

  const accountType = await AccountService.type()
  if (accountType !== AccountType.CREATOR) {
    return next({
      name: 'login'
    })
  }

  return next()
}
