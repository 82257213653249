/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BrandContactCreate } from '../models/BrandContactCreate';
import type { BrandContactRead } from '../models/BrandContactRead';
import type { UpdateNotificationsRequest } from '../models/UpdateNotificationsRequest';
import type { UpdatePhoneRequest } from '../models/UpdatePhoneRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class BrandContactService {
    /**
     * Create new brand contact
     * @param requestBody
     * @returns BrandContactRead Successful Response
     * @throws ApiError
     */
    public static add(
        requestBody: BrandContactCreate,
    ): CancelablePromise<BrandContactRead> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/brand_contacts/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * By Brand
     * @param brandId
     * @returns BrandContactRead Successful Response
     * @throws ApiError
     */
    public static byBrand(
        brandId: number,
    ): CancelablePromise<Array<BrandContactRead>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/brand_contacts/by_brand/{brand_id}',
            path: {
                'brand_id': brandId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * My Brand Contact
     * @param brandId
     * @returns BrandContactRead Successful Response
     * @throws ApiError
     */
    public static myBrandContact(
        brandId: number,
    ): CancelablePromise<BrandContactRead> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/brand_contacts/me/{brand_id}',
            path: {
                'brand_id': brandId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Phone
     * @param contactId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static updatePhone(
        contactId: number,
        requestBody: UpdatePhoneRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/brand_contacts/update-phone/{contact_id}',
            path: {
                'contact_id': contactId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Notifications
     * @param contactId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static updateNotifications(
        contactId: number,
        requestBody: UpdateNotificationsRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/brand_contacts/update-notifications/{contact_id}',
            path: {
                'contact_id': contactId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Remove social media account
     * @param contactId
     * @returns BrandContactRead Successful Response
     * @throws ApiError
     */
    public static remove(
        contactId: number,
    ): CancelablePromise<BrandContactRead> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/brand_contacts/remove/{contact_id}',
            path: {
                'contact_id': contactId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
