/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountCreate } from '../models/AccountCreate';
import type { AccountRead } from '../models/AccountRead';
import type { AccountStatus } from '../models/AccountStatus';
import type { AccountType } from '../models/AccountType';
import type { ChangePasswordRequest } from '../models/ChangePasswordRequest';
import type { ResetPasswordRequest } from '../models/ResetPasswordRequest';
import type { TokenAuthResponse } from '../models/TokenAuthResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AccountService {
    /**
     * Create
     * @param requestBody
     * @returns AccountRead Successful Response
     * @throws ApiError
     */
    public static create(
        requestBody: AccountCreate,
    ): CancelablePromise<AccountRead> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/accounts/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Type
     * @returns AccountType Successful Response
     * @throws ApiError
     */
    public static type(): CancelablePromise<AccountType> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/accounts/type',
        });
    }
    /**
     * Verify Email
     * @param accountId
     * @param token
     * @returns AccountStatus Successful Response
     * @throws ApiError
     */
    public static verifyEmail(
        accountId: number,
        token: string,
    ): CancelablePromise<AccountStatus> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/accounts/verify_email/{account_id}',
            path: {
                'account_id': accountId,
            },
            query: {
                'token': token,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Me
     * @returns AccountRead Successful Response
     * @throws ApiError
     */
    public static me(): CancelablePromise<AccountRead> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/accounts/me',
        });
    }
    /**
     * Update Email
     * @param newEmail
     * @returns AccountRead Successful Response
     * @throws ApiError
     */
    public static updateEmail(
        newEmail: string,
    ): CancelablePromise<AccountRead> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/accounts/me/email',
            query: {
                'new_email': newEmail,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Password
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static updatePassword(
        requestBody: ChangePasswordRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/accounts/me/password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Reset Password Request
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static resetPasswordRequest(
        requestBody: ResetPasswordRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/accounts/me/reset-password-request',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Accept Invitation
     * @param token
     * @returns any Successful Response
     * @throws ApiError
     */
    public static acceptInvitation(
        token: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/accounts/me/accept-invitation',
            query: {
                'token': token,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Validate Invitation
     * @param token
     * @returns any Successful Response
     * @throws ApiError
     */
    public static validateInvitation(
        token: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/accounts/me/validate-invitation',
            query: {
                'token': token,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Reset Password
     * @param token
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static resetPassword(
        token: string,
        requestBody: ChangePasswordRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/accounts/me/reset-password',
            query: {
                'token': token,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Resend Verification Email
     * @returns any Successful Response
     * @throws ApiError
     */
    public static resendVerificationEmail(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/accounts/me/resend-verification-email',
        });
    }
    /**
     * Token Auth
     * @param token
     * @returns TokenAuthResponse Successful Response
     * @throws ApiError
     */
    public static tokenAuth(
        token: string,
    ): CancelablePromise<TokenAuthResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/accounts/token-auth',
            query: {
                'token': token,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
