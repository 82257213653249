/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Dashboard } from '../models/Dashboard';
import type { DashboardFilters } from '../models/DashboardFilters';
import type { UIBadges } from '../models/UIBadges';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class StatsService {
    /**
     * Ui Badges
     * @param brandId
     * @returns UIBadges Successful Response
     * @throws ApiError
     */
    public static uiBadges(
        brandId: number,
    ): CancelablePromise<UIBadges> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/stats/ui-badges/{brand_id}',
            path: {
                'brand_id': brandId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Dashboard Filters
     * @param brandId
     * @returns DashboardFilters Successful Response
     * @throws ApiError
     */
    public static dashboardFilters(
        brandId: number,
    ): CancelablePromise<DashboardFilters> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/stats/dashboard/{brand_id}/filters',
            path: {
                'brand_id': brandId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Dashboard
     * @param brandId
     * @param locations
     * @param campaigns
     * @param period
     * @returns Dashboard Successful Response
     * @throws ApiError
     */
    public static dashboard(
        brandId: number,
        locations?: (Array<number> | null),
        campaigns?: (Array<number> | null),
        period: 'month' | 'quarter' | 'year' = 'month',
    ): CancelablePromise<Dashboard> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/stats/dashboard/{brand_id}',
            path: {
                'brand_id': brandId,
            },
            query: {
                'locations': locations,
                'campaigns': campaigns,
                'period': period,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
