import { createApp } from 'vue'

import Toast, { TYPE, useToast } from 'vue-toastification'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import { useFirebaseAuth, VueFire, VueFireAuth } from 'vuefire'
import { plugin as formKit, defaultConfig } from '@formkit/vue'
import { Crisp } from 'crisp-sdk-web'

import App from './App.vue'
import Icon from '@/components/Icons/Icon.vue'
import router from './router'

import './assets/main.css'

import * as Sentry from '@sentry/vue'

import { OpenAPI } from './client'
import { initializeApp } from 'firebase/app'
import { connectAuthEmulator } from "firebase/auth"

import formkitConfig from '../formKit.config'
import { useMainStore } from '@/stores/main'
import 'vue-toastification/dist/index.css'
import axios from 'axios'
import stores from '@/stores'
import Hotjar from '@hotjar/browser';


const app = createApp(App)

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID
}
const firebaseApp = initializeApp(firebaseConfig)

Crisp.configure(import.meta.env.VITE_CRISP_ID);

const toastDefaultOptions = {
  hideProgressBar: false,
  pauseOnFocusLoss: false,
  draggable: false,
  timeout: 5000
}

app.use(Toast, {
  transition: {
    enter: "toast-fade-enter-active",
    leave: "toast-none",
    move: "toast-move"
  },
  maxToasts: 5,
  newestOnTop: true,
  toastDefaults: {
    [TYPE.INFO]: toastDefaultOptions,
    [TYPE.WARNING]: toastDefaultOptions,
    [TYPE.ERROR]: toastDefaultOptions,
    [TYPE.SUCCESS]: toastDefaultOptions
  }
})
app.use(VueFire, {
  // imported above but could also just be created here
  firebaseApp,
  modules: [
    // we will see other modules later on
    VueFireAuth()
  ]
})
app.use(stores)
app.use(router)
app.use(autoAnimatePlugin)
app.use(formKit, defaultConfig(formkitConfig))

const isProduction = import.meta.env.VITE_APP_ENV == 'production'

if (isProduction) {
  const siteId = 3318317;
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion);
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      }),
      new Sentry.Replay({
        maskAllInputs: false,
        maskAllText: false
      })
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracePropagationTargets: ['localhost', 'bulba.app', /^\//]
  })
}

OpenAPI.BASE = import.meta.env.VITE_API_URL

const mainStore = useMainStore()
mainStore.loadGoogleMaps()

if (import.meta.env.VITE_APP_ENV == "local") {
  connectAuthEmulator(useFirebaseAuth()!, import.meta.env.VITE_FIREBASE_AUTH_EMULATOR_HOST)
}

app.component('Icon', Icon)
app.mount('#app')

axios.interceptors.request.use(
  config => {
    const data = config.data
    if (data && typeof data == "object" && data.hasOwnProperty("__form_feedback")) {
      config["__form_feedback"] = true
      delete data["__form_feedback"]
    }

    return config
  },
  err => {

    return Promise.reject(err);
  }
)

const toast = useToast()

axios.interceptors.response.use(
  response => {
    if (response.config["__form_feedback"]) {
      toast.success("Saved successfully")
    }

    return response
  },
  err => {
    if (err.config['__form_feedback']) {
      toast.error('There was an error while saving the form')
    }

    if (err.response && err.response.status == 403) {
      router.push({ name: 'unauthorized' })
    }

    return Promise.reject(err)
  }
)