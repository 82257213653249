import { getCurrentUser } from 'vuefire'
import { AccountService, AccountType, OpenAPI } from '@/client'
import { useFirebaseAuth } from 'vuefire'
import type { NavigationGuardNext } from 'vue-router'
import { useMainStore } from '@/stores/main'
import stores from '@/stores'
import type { MiddlewareContext } from '@/router/types.ts'

const mainStore = useMainStore(stores)

export default async (context: MiddlewareContext) => {
  const currentUser = await getCurrentUser()

  if (currentUser) {
    const auth = useFirebaseAuth()!
    // @ts-ignore
    OpenAPI.TOKEN = async () => auth.currentUser?.getIdToken(false)

    const accountType = await AccountService.type()

    if (accountType === AccountType.CREATOR) {
      return context.next({ name: 'creator' })
    } else {
      if (context.to.name == 'signup') {
        if (!mainStore.currentBrand) {
          await mainStore.initialize()
        }

        if (mainStore.brands.length == 0) {
          return context.next()
        }
      }
      return context.next({ name: 'business' })
    }
  }

  return context.next()
}
