/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum AccountType {
    CREATOR = 'creator',
    BUSINESS = 'business',
    AGENCY = 'agency',
    NONE = 'none',
}
