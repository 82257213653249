<template>
  <RouterLink
    class="button"
    :class="classes"
    :to="to"
    v-if="to"
  >
    <slot />
  </RouterLink>
  <a
    class="button"
    :class="classes"
    :href="href"
    v-else-if="href"
  >
    <slot />
  </a>
  <button
    :type="type"
    class="button"
    :class="classes"
    v-else
  >
    <div
      class="flex items-center justify-center"
      :class="{ 'opacity-0': loading }"
    >
      <slot />
    </div>

    <div
      v-if="loading"
      class="loader"
    >
      <IconCircleNotch
        width="18"
        height="18"
        class="animate-spin"
      />
    </div>
  </button>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import IconCircleNotch from '@/components/Icons/IconCircleNotch.vue'
import type { RouteLocationRaw } from 'vue-router'

type Size = 'xs' |
  'sm' |
  'md' |
  'lg' |
  'xl' |
  'icon'

type Color =
  'default' |
  'primary' |
  'danger'  |
  'text'    |
  'icon'

interface Props {
  type?: string,
  to?: RouteLocationRaw,
  href?: string

  size?: Size,
  color?: Color,
  subtle?: boolean,

  loading?: boolean,

  disabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  type: 'button',
  size: 'md',
  color: 'primary',
  subtle: false,
  loading: false,
})

const classes = computed(() => {
  let classes: string[] = []

  switch (props.size) {
    case 'xs': classes.push('px-2 py-1 text-xs'); break;
    case 'sm': classes.push('px-2 py-1 text-sm'); break;
    case 'md': classes.push('px-4 py-2 text-sm'); break;
    case 'lg': classes.push('px-5 py-2.5 text-sm'); break;
    case 'xl': classes.push('px-6 py-3 text-base'); break;
    case 'icon': classes.push('h-8 w-8 p-0'); break;
  }

  switch (props.color) {
    case 'default': classes.push('default'); break;
    case 'primary': classes.push('primary'); break;
    case 'danger': classes.push('danger'); break;
    case 'text': classes.push('text'); break;
    case 'icon': classes.push('bg-blue-100 hover:bg-blue-200'); break;
  }

  if (props.loading) {
    classes.push('loading')
  }

  if (props.subtle) {
    classes.push('subtle')
  }

  if (props.disabled) {
    classes.push('pointer-events-none opacity-50')
  }

  return classes
})
</script>

<style scoped>
.button {
  @apply
    relative
    inline-flex
    items-center
    justify-center
    font-medium
    rounded
    transition
    cursor-pointer
    focus-visible:outline
    focus-visible:outline-2
    focus-visible:outline-offset-2
    focus-visible:outline-indigo-600;
}

.button.text {
  @apply
    text-slate-700
    hover:text-slate-950;
}

.button.default {
  @apply
    bg-white
    text-slate-600
    shadow-sm
    ring-1
    ring-inset
    ring-slate-300
    hover:bg-slate-50;
}

.button.primary {
  @apply
    shadow-sm
    text-white
    bg-purple-900
    hover:bg-purple-950;
}

.button.primary.subtle {
  @apply
    bg-purple-100
    text-purple-900
    hover:bg-purple-200;
}

.button.danger {
  @apply
    shadow-sm
    text-white
    bg-red-500
    hover:bg-red-600;
}

.button.danger.subtle {
  @apply
    bg-red-100
    text-red-900
    hover:bg-red-200;
}

.button.loading {
  @apply pointer-events-none;
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}

.button.default .loader :deep(g) {
  @apply fill-slate-900;
}

.button.primary .loader :deep(g) {
  @apply fill-white;
}

.button.danger .loader :deep(g) {
  @apply fill-white;
}

.button.subtle .loader :deep(g) {
  @apply fill-slate-900;
}
</style>
