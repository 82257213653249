/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_brand_create } from '../models/Body_brand_create';
import type { Body_brand_setup } from '../models/Body_brand_setup';
import type { BrandRead } from '../models/BrandRead';
import type { CampaignDetails } from '../models/CampaignDetails';
import type { FeatureRequest } from '../models/FeatureRequest';
import type { Page_CreatorOffer_ } from '../models/Page_CreatorOffer_';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class BrandService {
    /**
     * Setup
     * @param requestBody
     * @param referralCreator
     * @param referralBusiness
     * @returns BrandRead Successful Response
     * @throws ApiError
     */
    public static setup(
        requestBody: Body_brand_setup,
        referralCreator?: (string | null),
        referralBusiness?: (number | null),
    ): CancelablePromise<BrandRead> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/brands/setup',
            query: {
                'referral_creator': referralCreator,
                'referral_business': referralBusiness,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get By User
     * @returns BrandRead Successful Response
     * @throws ApiError
     */
    public static getByUser(): CancelablePromise<Array<BrandRead>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/brands/by_user',
        });
    }
    /**
     * Create
     * @param requestBody
     * @returns BrandRead Successful Response
     * @throws ApiError
     */
    public static create(
        requestBody: Body_brand_create,
    ): CancelablePromise<BrandRead> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/brands/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Creator Offers
     * @param brandId
     * @param pageCursor
     * @param pageLimit
     * @returns Page_CreatorOffer_ Successful Response
     * @throws ApiError
     */
    public static creatorOffers(
        brandId: number,
        pageCursor?: (string | null),
        pageLimit: number = 100,
    ): CancelablePromise<Page_CreatorOffer_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/brands/{brand_id}/creator_offers',
            path: {
                'brand_id': brandId,
            },
            query: {
                'page_cursor': pageCursor,
                'page_limit': pageLimit,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Detailed Campaigns
     * @param brandId
     * @returns CampaignDetails Successful Response
     * @throws ApiError
     */
    public static getDetailedCampaigns(
        brandId: number,
    ): CancelablePromise<Array<CampaignDetails>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/brands/{brand_id}/detailed_campaigns',
            path: {
                'brand_id': brandId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Generate Demo
     * @returns BrandRead Successful Response
     * @throws ApiError
     */
    public static generateDemo(): CancelablePromise<BrandRead> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/brands/generate-demo',
        });
    }
    /**
     * Request Feature
     * @param brandId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static requestFeature(
        brandId: number,
        requestBody: FeatureRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/brands/{brand_id}/request-feature',
            path: {
                'brand_id': brandId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Exists Name
     * @param name
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static existsName(
        name: string,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/brands/exists',
            query: {
                'name': name,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get
     * @param brandId
     * @returns BrandRead Successful Response
     * @throws ApiError
     */
    public static get(
        brandId: number,
    ): CancelablePromise<BrandRead> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/brands/{brand_id}',
            path: {
                'brand_id': brandId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
