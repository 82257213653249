/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BrandSocialCreate } from '../models/BrandSocialCreate';
import type { BrandSocialRead } from '../models/BrandSocialRead';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class BrandSocialService {
    /**
     * Connect new social media account
     * @param requestBody
     * @returns BrandSocialRead Successful Response
     * @throws ApiError
     */
    public static add(
        requestBody: BrandSocialCreate,
    ): CancelablePromise<BrandSocialRead> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/brand_socials/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * By Brand
     * @param brandId
     * @returns BrandSocialRead Successful Response
     * @throws ApiError
     */
    public static byBrand(
        brandId: number,
    ): CancelablePromise<Array<BrandSocialRead>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/brand_socials/by_brand/{brand_id}',
            path: {
                'brand_id': brandId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Remove social media account
     * @param socialId
     * @returns BrandSocialRead Successful Response
     * @throws ApiError
     */
    public static remove(
        socialId: number,
    ): CancelablePromise<BrandSocialRead> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/brand_socials/remove/{social_id}',
            path: {
                'social_id': socialId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
