/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_location_upload_photos } from '../models/Body_location_upload_photos';
import type { LocationCreate } from '../models/LocationCreate';
import type { LocationDetails } from '../models/LocationDetails';
import type { LocationRead } from '../models/LocationRead';
import type { LocationUpdate } from '../models/LocationUpdate';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class LocationService {
    /**
     * Get By Brand
     * @param brandId
     * @returns LocationRead Successful Response
     * @throws ApiError
     */
    public static getByBrand(
        brandId: number,
    ): CancelablePromise<Array<LocationRead>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/locations/by_brand',
            query: {
                'brand_id': brandId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Count By Brand
     * @param brandId
     * @returns number Successful Response
     * @throws ApiError
     */
    public static getCountByBrand(
        brandId: number,
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/locations/count_by_brand',
            query: {
                'brand_id': brandId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Add
     * @param requestBody
     * @returns LocationDetails Successful Response
     * @throws ApiError
     */
    public static add(
        requestBody: LocationCreate,
    ): CancelablePromise<LocationDetails> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/locations/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Modify
     * @param locationId
     * @param requestBody
     * @returns LocationDetails Successful Response
     * @throws ApiError
     */
    public static modify(
        locationId: number,
        requestBody: LocationUpdate,
    ): CancelablePromise<LocationDetails> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/locations/update/{location_id}',
            path: {
                'location_id': locationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Upload Photos
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    public static uploadPhotos(
        formData: Body_location_upload_photos,
    ): CancelablePromise<(Array<string> | null)> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/locations/upload-photos',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete
     * @param locationId
     * @returns LocationDetails Successful Response
     * @throws ApiError
     */
    public static delete(
        locationId: number,
    ): CancelablePromise<LocationDetails> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/locations/{location_id}',
            path: {
                'location_id': locationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get
     * @param locationId
     * @returns LocationDetails Successful Response
     * @throws ApiError
     */
    public static get(
        locationId: number,
    ): CancelablePromise<LocationDetails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/locations/{location_id}',
            path: {
                'location_id': locationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Close
     * @param locationId
     * @returns LocationDetails Successful Response
     * @throws ApiError
     */
    public static close(
        locationId: number,
    ): CancelablePromise<LocationDetails> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/locations/{location_id}/close',
            path: {
                'location_id': locationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
