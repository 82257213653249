import { defineStore, type StoreDefinition } from 'pinia'
import { getCurrentUser } from 'vuefire'
import {
  AccountService,
  type BrandRead,
  CampaignService,
  CollaborationService,
  OpenAPI,
  PostService,
  StatsService
} from '@/client'
import { useApi } from '@/composables/useApi'

import { Loader } from '@googlemaps/js-api-loader'
import type { MainStore } from '@/stores/types'
import { StreamChat } from 'stream-chat'
import { Crisp } from 'crisp-sdk-web'
import dayjs from 'dayjs'

const { getBrands, findBrand } = useApi()

export const useMainStore: StoreDefinition<'main', MainStore, { trialDaysLeft }> = defineStore(
  'main',
  {
    state: (): MainStore => ({
      currentUser: null,
      currentAccount: null,
      brands: [],
      currentBrand: null,
      isGoogleMapsLoaded: false
    }),

    actions: {
      async initialize() {
        this.currentUser = (await getCurrentUser()) ?? null

        if (!this.currentUser) {
          return
        }

        OpenAPI.TOKEN = await this.currentUser.getIdToken(true)

        await this.loadBrands()

        this.currentAccount = await AccountService.me()
        Crisp.setTokenId(this.currentAccount.uid)
        Crisp.user.setEmail(this.currentAccount.email)
      },

      async loadBrands() {
        this.brands = await getBrands()
      },

      async findBrand(id: number) {
        return findBrand(id)
      },

      selectBrand(id: number) {
        this.setBrand(this.brands.find((brand) => brand.id === id) ?? null)
      },

      selectFirstBrand() {
        if (this.brands.length > 0) {
          this.setBrand(this.brands[0])
        }
      },

      async setBrand(brand: BrandRead | null) {
        this.currentBrand = brand

        if (this.currentBrand) {
          localStorage.setItem('currentBrand', this.currentBrand.id.toString())
          Crisp.session.setData({
            current_brand_id: this.currentBrand.id,
            current_brand_name: this.currentBrand.name
          })
        } else {
          Crisp.session.setData({})
          localStorage.removeItem('currentBrand')
        }

        this.refreshBrandStats()
      },

    refreshBrandStats() {
      if (!this.currentBrand) {
        return
      }

      StatsService.uiBadges(this.currentBrand.id).then(result => {
        if (this.currentBrand) {
          this.currentBrand.collaborationStats = result.collab_status_count
          this.currentBrand.newPosts = result.new_posts_count
          this.currentBrand.campaignsPendingCollabs = result.pending_collabs_count
          this.currentBrand.current_month_collabs = result.current_month_collabs
        }
      })

      if (!this.currentBrand.chatClient && this.currentBrand.stream_token) {
        this.currentBrand.chatClient = new StreamChat(import.meta.env.VITE_STREAM_API_KEY)
        this.currentBrand.chatClient.connectUser(
          {
            id: 'brand-' + this.currentBrand.id
          },
          this.currentBrand.stream_token
        ).then((response) => {
          if (this.currentBrand) {
            this.currentBrand.newMessages = response?.me?.unread_channels
          }
        })

        this.currentBrand.chatClient.on((event) => {
          if (event.unread_channels !== undefined) {
            this.currentBrand!.newMessages = event.unread_channels
          }
        })
      }
    },

      async loadGoogleMaps() {
        try {
          const loader = new Loader({
            apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
            version: '3.52',
            libraries: ['places']
          })

          await loader.load()

          this.isGoogleMapsLoaded = true
        } catch (error) {
          console.error('Error loading Google Maps: ', error)
        }
      }
    },

    getters: {
      trialDaysLeft(state): number | null {
        if (!state.currentBrand || !state.currentBrand.is_trialing) {
          return null
        }

        const left = dayjs(state.currentBrand.trial_ends_at).diff(dayjs(), 'days')
        return left > 0 ? left : null
      }
    }
  }
)
