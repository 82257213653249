import { generateClasses } from '@formkit/themes'
import { createProPlugin, inputs } from '@formkit/pro'

import {
  createAutoAnimatePlugin,
  createAutoHeightTextareaPlugin,
  createMultiStepPlugin
} from '@formkit/addons'

import theme from './src/assets/theme'

import { createInput, type DefaultConfigOptions } from '@formkit/vue'
import FormKitPhotos from '@/components/FormKitInputs/FormKitPhotos.vue'
import FormKitCalendar from '@/components/FormKitInputs/FormKitCalendar.vue'
import { useToast } from 'vue-toastification'

const toast = useToast()

const submitFeedback = (node) => {
  if (node.type == 'group') {
    node.hook.message((payload, next) => {
      if (payload.key == "submitted" && node.ledger.value('blocking') > 0) {
        toast.error("Submission failed! Please check the form for errors.", {timeout: 20000})
      }

      return next(payload)
    })

    node.hook.submit((payload, next) => {
      payload["__form_feedback"] = true

      return next(payload)
    })
  }
}


const config: DefaultConfigOptions = {
  inputs: {
    photos: createInput(FormKitPhotos),
    calendar: createInput(FormKitCalendar),
  },
  config: {
    classes: generateClasses(theme)
  },
  plugins: [
    // TODO: Move to .env
    createProPlugin('fk-37927a84e', inputs),
    createAutoAnimatePlugin(),
    createMultiStepPlugin(),
    createAutoHeightTextareaPlugin(),
    submitFeedback
  ]
}

export default config
