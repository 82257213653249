import { getCurrentUser } from 'vuefire'
import { AccountService, AccountType, OpenAPI } from '@/client'
import { useFirebaseAuth } from 'vuefire'
import { jwtDecode } from 'jwt-decode'
import { signInWithCustomToken, User } from 'firebase/auth'
import { useMainStore } from '@/stores/main'
import stores from '@/stores'
import type { MiddlewareContext } from '@/router/types'

const mainStore = useMainStore(stores)

export default async (context: MiddlewareContext) => {
  let currentUser = await getCurrentUser()

  try {
    const authenticated = await urlTokenAuth(currentUser)
    if (authenticated) {
      currentUser = await getCurrentUser()
    }
  } catch (e) { /* empty */ }
  
  if (!currentUser) {
    return context.next({
      name: 'login'
    })
  }

  const auth = useFirebaseAuth()!

  // @ts-ignore
  OpenAPI.TOKEN = async () => auth.currentUser?.getIdToken(false)

  if (!mainStore.currentBrand) {
    await mainStore.initialize()

    let brandId: number | null = parseInt(context.to.params.brandId as string)

    if (!brandId) {
      brandId = parseInt(localStorage.getItem("currentBrand") ?? "")
    }

    if (mainStore.brands.find(item => item.id == brandId)) {
      mainStore.selectBrand(brandId)
    } else {
      mainStore.selectFirstBrand()
    }
  }

  const accountType = mainStore.currentAccount?.type

  if (accountType !== AccountType.BUSINESS && accountType !== AccountType.AGENCY) {
    return context.next({
      name: 'login'
    })
  }

  return context.next()
}

async function urlTokenAuth(currentUser: User | null | undefined): Promise<boolean> {
  const urlParams = new URLSearchParams(window.location.search)
  const authQueryParam = urlParams.get('auth')

  if (!authQueryParam) {
    return false
  }
  
  const authJwt = jwtDecode(authQueryParam)
  
  if (currentUser && currentUser.uid == authJwt.sub) {
    return false
  }
  
  const response = await AccountService.tokenAuth(authQueryParam)
  
  const auth = useFirebaseAuth()!
  await signInWithCustomToken(auth, response.token)

  return true
}