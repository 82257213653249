<template>
<div>
  <div ref="photoContainer" class="flex space-x-5 overflow-x-auto py-2">
    <div v-for="photo in photos" :key="photo.id" class="group photo-item flex-shrink-0 relative"
    >
      <div class="group-hover:visible invisible absolute bg-black/40 left-0 right-0 top-0 bottom-0 rounded-lg pointer-events-none flex items-center justify-center">
        <div class="text-white/90 flex space-x-4">
          <ArrowLeftIcon class="w-12"/>
          <ArrowRightIcon class="w-12"/>
        </div>
      </div>
      <img :src="photo.source" class="drag-handle cursor-grab h-36 rounded-lg shadow-lg aspect-[16/9] object-cover" />
      <div v-if="photo.uploading" class="absolute top-2 right-2 bg-gray-100 p-1.5 rounded border border-gray-400">
        <IconCircleNotch class="w-5 h-5 animate-spin"/>
      </div>
      <template v-else>
        <div v-if="photo.file" class="absolute flex items-center top-2 right-2 bg-gray-50 p-0.5 rounded border border-gray-400">
          <span class="text-red-600 font-semibold text-sm px-3">Upload failed</span>
          <button type="button" class="top-2 right-2 bg-gray-100 p-1.5 rounded border border-gray-400
                   text-gray-600 hover:bg-gray-200" @click="uploadPhoto(photo.id)"
          >
            <ArrowPathIcon class="w-5 h-5" />
          </button>
          <button type="button" class="ml-1.5 top-2 right-2 bg-gray-100 p-1.5 rounded border border-gray-400
                   text-red-500 hover:bg-gray-200" @click="removePhoto(photo.id)"
          >
            <TrashIcon class="w-5 h-5" />
          </button>
        </div>
        <button v-else type="button" class="absolute top-2 right-2 bg-gray-100 p-1.5 rounded border border-gray-400
                 text-red-500 hover:bg-gray-200" @click.prevent.stop="removePhoto(photo.id)"
        >
          <TrashIcon class="w-5 h-5" />
        </button>
      </template>
    </div>

    <label class="h-36 rounded-lg shadow-lg aspect-[16/9] bg-gray-300 text-gray-50 hover:bg-gray-400/60
              cursor-pointer flex items-center justify-center"
    >
      <PlusCircleIcon class="w-20 h-20"></PlusCircleIcon>
      <input multiple type="file" class="hidden" accept="image/png,image/jpg,image/jpeg" @change="photoSelected"/>
    </label>
  </div>
</div>
</template>

<script setup lang="ts">
import type { FormKitFrameworkContext } from '@formkit/core'
import { TrashIcon } from '@heroicons/vue/20/solid'
import { ArrowPathIcon, PlusCircleIcon, ArrowLeftIcon, ArrowRightIcon } from '@heroicons/vue/24/outline'
import { onMounted, ref, watch } from 'vue'
import { LocationService } from '@/client'
import IconCircleNotch from '@/components/Icons/IconCircleNotch.vue'
import { Sortable, type SortableSortedEvent } from '@shopify/draggable'

interface Props {
  context: FormKitFrameworkContext
}

const props = defineProps<Props>()

let nextId = 1

const photos = ref<
  {
    id: number,
    source: string | ArrayBuffer,
    uploading: boolean,
    file: File | null
  }[]>([])

const photoContainer = ref<null | HTMLElement>(null);

watch(() => props.context.value, newValue => {
  if (Array.isArray(newValue)) {
    const existingPhotos = photos.value.filter(item => item.uploading || item.file || newValue.includes(item.source))

    newValue.forEach(photo => {
      if (existingPhotos.find(item => (!item.uploading || !item.file) && item.source == photo)) {
        return
      }

      existingPhotos.push({id: getNextId(), source: photo, uploading: false, file: null})
    })

    photos.value = existingPhotos
  } else {
    photos.value = photos.value.filter(item => item.uploading || item.file)
  }
}, {immediate: true})

function getNextId(): number {
  return nextId++
}

function photoSelected(e: Event) {
  const files: FileList | null = (e.target as HTMLInputElement).files

  if (!files) {
    return
  }

  for (let i = 0; i < files.length; i++) {
    const fileReader = new FileReader()

    fileReader.onload = async event => {
      if (event.target?.result) {
        const id = getNextId()
        photos.value = [...photos.value, { id: id, source: event.target.result, uploading: true, file: files[i]}]
        await uploadPhoto(id)
      }
    }

    fileReader.readAsDataURL(files[i])
  }
}

async function uploadPhoto(id: number) {
  try {
    const photo = photos.value.find(item => item.id == id && item.file)
    if (!photo || !photo.file) {
      return
    }

    photo.uploading = true

    const urls = await LocationService.uploadPhotos({ photos: [photo.file] })

    if (urls && urls.length) {
      const photoIndex = photos.value.findIndex(item => item.id == id)
      if (photoIndex == -1) {
        return
      }

      photos.value[photoIndex].source = urls[0]
      photos.value[photoIndex].uploading = false
      photos.value[photoIndex].file = null

      updateModelValue()
    } else {
      throw new Error("Missing photo url from the response")
    }
  } catch (e) {
    const photoIndex = photos.value.findIndex(item => item.id == id)
    if (photoIndex == -1) {
      return
    }

    photos.value[photoIndex].uploading = false
  }
}

function removePhoto(id: number) {
  const photoIndex = photos.value.findIndex(item => item.id == id)
  if (photoIndex == -1) {
    return
  }

  photos.value.splice(photoIndex, 1)

  updateModelValue()
}

function photosSorted(e: SortableSortedEvent) {
  const tmp = photos.value[e.oldIndex]
  photos.value[e.oldIndex] = photos.value[e.newIndex]
  photos.value[e.newIndex] = tmp
  updateModelValue()
}

function updateModelValue() {
  props.context.node.input(photos.value
    .filter(item => !item.uploading || !item.file)
    .map(item => item.source)
  )
}

onMounted(() => {
  if (!photoContainer.value) {
    return
  }

  const sortable = new Sortable(photoContainer.value, {
    draggable: '.photo-item',
    handle: ".drag-handle",
    mirror: {
      yAxis: false
    }
  })

  sortable.on("sortable:sorted", photosSorted)
})
</script>

<style scoped>
.draggable--over {
  opacity: 20%;
}
.draggable-mirror {
  border-radius: 10px;
  box-shadow: 0 0 9px 2px #00000045;
}
</style>