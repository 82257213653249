import type { MiddlewareContext } from '@/router/types'
import business from '@/router/middlewares/business'

// same as the business middleware but it still loads the page if unauthenticated instead of redirecting to login page
export default async (context: MiddlewareContext) => {
  return business({
    ...context,
    next: () => {
      return context.next()
    }
  })
}