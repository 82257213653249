import {
  BrandContactService,
  BrandService,
  BrandSocialService,
  CampaignService,
  CancelablePromise,
  CollaborationService,
  LocationService
} from "@/client";

import type {
  BrandRead,
  BrandContactRead,
  BrandContactCreate,
  BrandSocialRead,
  BrandSocialCreate,
  LocationRead,
  CampaignDetails,
  LocationDetails,
  CollaborationDetails
} from '@/client'

export const useApi = () => {
  const getBrands = (): CancelablePromise<Array<BrandRead>> => {
    return BrandService.getByUser()
  }

  const findBrand = (id: number): CancelablePromise<BrandRead> => {
    return BrandService.get(id)
  }

  const findCampaign = (id: number): CancelablePromise<CampaignDetails> => {
    return CampaignService.get(id)
  }

  const getCampaignsByBrand = (
    brandId: number
  ):  CancelablePromise<CampaignDetails[]> => {
    return BrandService.getDetailedCampaigns(brandId)
  }

  const findLocation = (id: number): CancelablePromise<LocationDetails> => {
    return LocationService.get(id)
  }

  const removeLocation = async (id: number) => {
    return LocationService.delete(id)
  }

  const getLocationsByBrand = (
    brandId: number
  ): CancelablePromise<LocationRead[]>  => {
    return LocationService.getByBrand(brandId)
  }

  const getSocialsByBrand = (
    id: number
  ): CancelablePromise<BrandSocialRead[]> => {
    return BrandSocialService.byBrand(id)
  }

  const removeSocial = (socialId: number) => {
    return BrandSocialService.remove(socialId)
  }

  const storeSocial = (
    requestBody: BrandSocialCreate
  ): CancelablePromise<BrandSocialRead> => {
    return BrandSocialService.add(requestBody)
  }

  const getMembersByBrand = (
    brandId: number
  ): CancelablePromise<BrandContactRead[]> => {
    return BrandContactService.byBrand(brandId)
  }

  const storeMember = (
    requestBody: BrandContactCreate
  ): CancelablePromise<BrandContactRead> => {
    return BrandContactService.add(requestBody)
  }

  const getCollaborationsByBrand = (
    brandId: number
  ): CancelablePromise<Array<CollaborationDetails>> => {
    return CollaborationService.getByBrand(brandId)
  }

  const acceptCollaboration = (
    id: number,
    notes?: string
  ): CancelablePromise<CollaborationDetails> => {
    return CollaborationService.approve(id, notes ?? '')
  }

  const rescheduleCollaboration = (
    id: number,
    reason: string
  ): CancelablePromise<CollaborationDetails> => {
    return CollaborationService.requestReschedule(id, reason)
  }

  const declineCollaboration = (
    id: number,
    reason: string
  ): CancelablePromise<CollaborationDetails> => {
    return CollaborationService.reject(id, reason)
  }

  return {
    getBrands,
    findBrand,

    findCampaign,
    getCampaignsByBrand,

    acceptCollaboration,
    declineCollaboration,
    getCollaborationsByBrand,

    findLocation,
    removeLocation,
    getLocationsByBrand,

    storeSocial,
    removeSocial,
    getSocialsByBrand,

    storeMember,
    getMembersByBrand,
  }
}
